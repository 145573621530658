var maxWindowWidth = 767; // window.innerWidth
var windowWidth = window.innerWidth;
var butId = 'but-menu';
var navId = 'nav';
var aniDur = 500;

var nav = document.getElementById(navId);
document.getElementById(butId).addEventListener('click', menuButton);

initNav();
navChecks();

function initNav() {
	var subMenus = nav.querySelector('ul').querySelectorAll('ul');

	for (let i = 0; i < subMenus.length; i++) {
		let link = subMenus[i].parentNode.querySelector('a');

		link.addEventListener('click', subClick);
	}

	window.onresize = resizeCheck;

	document.onclick = documentCheck;
}

function documentCheck() {
	if (!checkWidth()) {
		return;
	}

	if (event.target.id != butId && !nav.contains(event.target)) {
		closeMenu(nav.querySelector('ul'));
	}
}

function resizeCheck() {
	if (window.innerWidth != windowWidth) {
		windowWidth = window.innerWidth;
		navChecks();
	}
}

function navChecks() {
	if (checkWidth()) {
		setMenus(nav);
		closeMenu(nav.querySelector('ul'));
	} else {
		removeMenus(nav);
	}
}

function openMenu(el) {
	el.classList.remove('sub-close');

	el.style.height = el.scrollHeight + 'px';

	setTimeout(function () {
		el.style.height = '';
		el.classList.add('sub-open');
	}, aniDur);
}

function closeMenu(el) {
	el.style.height = el.scrollHeight + 'px';
	el.classList.remove('sub-open');

	closeSubMenus(el);

	setTimeout(function () {
		el.style.height = 0 + 'px';
	}, 1);

	setTimeout(function () {
		el.style.height = '';
		el.classList.add('sub-close');
	}, aniDur);
}

function closeSubMenus(el) {
	var elChildren = checkChildren(el);

	if (elChildren.length) {
		for (let i = 0; i < elChildren.length; i++) {
			closeMenu(elChildren[i]);
		}
	}
}

function closeOpenSubMenus(el) {
	var elChildren = checkChildren(el);

	if (elChildren.length) {
		for (let i = 0; i < elChildren.length; i++) {
			if (elChildren[i].classList.contains('sub-open')) {
				closeMenu(elChildren[i]);
			}
		}
	}
}

function checkChildren(el) {
	var uls = el.querySelectorAll('ul');
	return uls;
}

function setMenus(el) {
	var uls = el.querySelectorAll('ul');

	for (let i = 0; i < uls.length; i++) {
		uls[i].classList.add('sub-mobile');
	}
}

function removeMenus(el) {
	var uls = el.querySelectorAll('ul');

	for (let i = 0; i < uls.length; i++) {
		uls[i].classList.remove('sub-mobile');
	}
}

function subClick() {
	if (!checkWidth()) {
		return;
	}

	var link = this;
	var el = link.parentNode.querySelector('ul');

	if (el.classList.contains('sub-close')) {
		event.preventDefault();

		var parentMenu = el.parentNode.parentNode;
		closeOpenSubMenus(parentMenu);
		openMenu(el);
	}
}

function menuButton() {
	if (!checkWidth()) {
		return;
	}

	var mainUl = nav.querySelector('ul');

	if (mainUl.classList.contains('sub-open')) {
		closeMenu(mainUl);
	} else if (mainUl.classList.contains('sub-close')) {
		openMenu(mainUl);
	}
}

function checkWidth() {
	return window.innerWidth <= maxWindowWidth;
}
